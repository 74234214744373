<template>
  <div class="h-auto">
    <div class="hero">
      <div class="container">
        <div
          class="wow fadeIn hero_info"
          data-wow-duration="4s"
        >
          <h1>
            重建<br>
            亞特蘭提斯
          </h1>
          <div class="d-flex">
            <a
              href="#"
              class="btn btn-outline-light btn-lg"
              data-bs-toggle="modal"
              data-bs-target="#connectModal"
            >連結錢包</a>
            <a
              href="detail_tw"
              class="btn btn-primary btn-lg ms-3"
            >鑄造</a>
          </div>
        </div>
        <img
          src="img/light.png"
          class="wow fadeIn infinite bg_img"
          data-wow-duration="3s"
          alt=""
        >
        <img
          src="img/shadow.png"
          class="wow fadeIn infinite bg_img"
          alt=""
        >
        <img
          src="img/fish04.png"
          class="wow slideInRight fish01"
          data-wow-duration="2s"
          alt=""
        >
        <div id="particles-js" />
      </div>
    </div>

    <div class="bg_gradient">
      <!-- info -->
      <section
        id="info"
        class="section_info"
      >
        <div class="container">
          <div class="row gx-0 gx-lg-5 mb-5 align-items-center">
            <div class="col-lg-7 wow fadeInLeft">
              <h2 class="h2">
                起源
              </h2>
              <p>柏拉圖，古希臘哲學之祖，創造了亞特蘭提斯的傳說。</p>
              <p>
                大約 9000
                年前，在大力神之柱（直布羅陀海峽）的對面，有一個大島，您可以從那裡到達其他島嶼。這就是亞特蘭提斯王國。
              </p>
              <p>
                亞特蘭提斯當時正要與雅典開戰。突然，亞特蘭提斯遭到地震和洪水的襲擊。在不到一天一夜的時間裡，它就完全沉入海底，成為希臘人海上旅行的障礙。
              </p>
              <p>
                亞特蘭提斯是海神波塞冬創造的。有一個女孩的父母死在一個小島上。波塞冬娶了女孩，生了五對雙胞胎，於是他把全島分成十個區，任命十個兒子統治。長子是最高統治者。由於長子被命名為阿特拉斯（Άτλας），王國被稱為亞特蘭提斯。
              </p>
              <p>然而，沒有人知道亞特蘭提斯現在究竟在哪裡？</p>
              <p>或者它們是否還存在？</p>
            </div>
            <div class="col-lg-5 wow fadeInRight">
              <div class="home_shell float">
                <img
                  src="img/shell/1.png"
                  class="shell01"
                  alt=""
                >
                <img
                  src="img/shell/2.png"
                  class="shell02 wow fadeIn infinite"
                  data-wow-duration="7s"
                  alt=""
                >
                <img
                  src="img/shell/3.png"
                  class="shell03 wow flash infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/4.png"
                  class="shell04 wow fadeIn infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/5.png"
                  class="shell05"
                  alt=""
                >
                <img
                  src="img/shell/6.png"
                  class="shell06 wow fadeIn infinite"
                  data-wow-duration="1s"
                  alt=""
                >
                <img
                  src="img/shell/7.png"
                  class="shell07"
                  alt=""
                >
                <img
                  src="img/shell/8.png"
                  class="shell08 wow flash infinite"
                  data-wow-duration="6s"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="row gx-0 gx-lg-5 align-items-center">
            <div class="col-lg-7 order-2 order-lg-1 wow fadeInLeft">
              <img
                src="img/about.png"
                class="rounded w-100 float"
                alt=""
              >
            </div>
            <div class="col-lg-5 order-1 order-lg-2 wow fadeInRight">
              <h2 class="h2">
                元宇宙中的創世紀
              </h2>
              <p>由於海神波塞冬的召喚，亞特蘭提斯即將在以太元宇宙重生！</p>
              <p>
                為了創建 NFT，我們聚集了來自世界各地的 100 多名知名巫師！
                NFT
                擁有者可以成為未來亞特蘭提斯元宇宙的成員，享受各種鏈上鏈下的特權和折扣。
              </p>
              <p>
                與傳統的 NFT 項目隨機生成特徵不同，Atlantis-P 邀請了 100
                多位著名藝術家基於亞特蘭提斯王國 4 個種族的模板進行創作：<br>
                - 亞特蘭提斯守衛者<br>
                - 機械魟魚<br>
                - 亞特蘭提斯戰士<br>
                - 美人魚<br>
                每個 NFT 都是由真正的藝術家創作的獨一無二的藝術品。
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- team -->
      <section
        id="team"
        class="section_team"
      >
        <img
          src="img/fish03.png"
          class="wow fadeIn fish02"
          data-0="transform:translate(0%,0%)"
          data-top-bottom="transform:translate(120px,-150px)"
          alt=""
        >
        <div class="container">
          <div class="text-center mb-5 wow fadeInUp">
            <h2 class="h2">
              團隊介紹
            </h2>
          </div>
          <div class="position-relative">
            <div class="swiper wow fadeInUp">
              <div class="swiper-wrapper">
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/01.jpg')"
                    />
                    <div class="job_name">
                      Director
                    </div>
                    <div class="name">
                      M
                    </div>
                  </div>
                </div>
                <!-- item -->
                <!-- <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/03.jpg')"
                    />
                    <div class="job_name">
                      Consultant
                    </div>
                    <div class="name">
                      Eric
                    </div>
                  </div>
                </div> -->
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/04.jpg')"
                    />
                    <div class="job_name">
                      Main Artist
                    </div>
                    <div class="name">
                      Hot Dog life
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/05.jpg')"
                    />
                    <div class="job_name">
                      Main Artist/ Writer
                    </div>
                    <div class="name">
                      Erick Yang
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/06.jpg')"
                    />
                    <div class="job_name">
                      Main Artist
                    </div>
                    <div class="name">
                      Yu Rin
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/07.jpg')"
                    />
                    <div class="job_name">
                      IP designer
                    </div>
                    <div class="name">
                      Yen
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="swiper-slide">
                  <div class="team_item">
                    <div
                      class="image"
                      style="background-image: url('img/team/08.jpg')"
                    />
                    <div class="job_name">
                      Art consultant
                    </div>
                    <div class="name">
                      C.long
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination" />
            </div>
            <div class="swiper-button-prev d-none d-md-block" />
            <div class="swiper-button-next d-none d-md-block" />
          </div>
        </div>
      </section>
      <!-- Arts -->
      <section
        id="arts"
        class="section_arts"
      >
        <div class="container">
          <div class="text-center mb-5 wow fadeInUp">
            <h2 class="h2">
              作品精選
            </h2>
            <p>NFT史上最大規模藝術創作</p>
          </div>
          <div class="row gx-2 gx-sm-3 gx-lg-4 mb-5 wow fadeInUp">
            <!-- <div class="col-lg-3 col-md-4 col-6 mb-3 mb-lg-4">
            <div class="art_item">
              <div class="image" style="background-image: url('img/arts/01.jpg');">
                <div class="cover">
                  <a href="#" class="btn btn-primary mb-3">MINT</a>
                  <a href="#" class="btn btn-outline-light">VIEW</a>
                </div>
              </div>
              <div class="info">
                <div class="name">Artist name</div>
                <div class="title">大威德明王</div>
              </div>
            </div>
          </div> -->

            <div
              v-for="item in artList"
              :key="item.id"
              class="col-lg-3 col-md-4 col-6 mb-2 gx-sm-3 mb-lg-4"
            >
              <div class="art_item">
                <div
                  class="image"
                  :style="{ backgroundImage: 'url(' + item.img + ')' }"
                >
                  <div class="cover">
                    <!-- <a href="#" class="btn btn-primary mb-3" id="item.id">MINT</a> -->
                    <a
                      data-fancybox="gallery"
                      :href="item.img"
                      class="btn btn-outline-light"
                    >VIEW</a>
                  </div>
                </div>
                <div class="info">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="title">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="text-center">
            <a href="arts" class="btn btn-outline-light btn-lg px-5">MORE</a>
          </div> -->
        </div>
      </section>
      <!-- roadmap -->
      <section
        id="roadmap"
        class="section_roadmap"
      >
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="h2 wow fadeInUp">
              規劃藍圖
            </h2>
          </div>
          <div class="row">
            <div class="col-md-10 m-auto wow fadeInUp">
              <div class="roadmap">
                <ul class="list-unstyled list_roadmap">
                  <li class="wow fadeInUp">
                    <h4>第一階段</h4>
                    <p>
                      銷售數量達到總額的10%時，我們將會開放新的角色讓藝術家創作，同時也會開啟新一波的藝術家徵選，讓這個項目的創作更多元。
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>第二階段</h4>
                    <p>
                      銷售數量達到總額的30%時，我們將會開啟寵物空投計畫，預計空投100隻亞特蘭提斯特有寵物給NFT的持有者。
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>第三階段</h4>
                    <p>
                      銷售數量達到總額的50%時，我們就會正式啟動實體藝廊的計畫，所有亞特蘭提斯的子民將會有一個存在於現實的會所可以聚集。
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>第四階段</h4>
                    <p>
                      銷售數量達到總額的75%時，所有NFT上的子民將被喚醒，各位可以依照NFT的階級享有其賦能，並期待後續新增的其他賦能。
                    </p>
                  </li>
                  <li class="wow fadeInUp">
                    <h4>第五階段</h4>
                    <p>
                      銷售數量達到總額的100%時，我們將會在藝廊召開第一次亞特蘭提斯大會，所有的藝術家及
                      NFT 藏家將齊聚一堂，同時我們也將宣布啟動下一個重大計畫。
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- faq -->
      <section
        id="faq"
        class="section_faq"
      >
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="h2 wow fadeInUp">
              常見問題
            </h2>
          </div>
          <div class="row">
            <div class="col-md-10 m-auto wow fadeInUp">
              <div
                id="accordionExample"
                class="accordion"
              >
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq01"
                  >
                    什麼是亞特蘭提斯-P NFT？
                  </h3>
                  <div
                    id="faq01"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        它是以太坊上的數字資產。持有者可以使用它來享受亞特蘭提斯元宇宙的各項功能
                      </p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq02"
                  >
                    Mint 的價格是多少？
                  </h3>
                  <div
                    id="faq02"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>0.08ETH</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq03"
                  >
                    稀有度重要嗎？
                  </h3>
                  <div
                    id="faq03"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        是的。 <br>
                        從最高等級到普通等級：皇室、貴族、祭司、眾議員和榮譽市民。
                        <br>
                        <br>

                        每個職業都有獨一無二的附能。
                      </p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq04"
                  >
                    什麼時候發售？
                  </h3>
                  <div
                    id="faq04"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>2022年8月19日第一波啟動</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq05"
                  >
                    次級市場在哪？
                  </h3>
                  <div
                    id="faq05"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>OpenSea</p>
                    </div>
                  </div>
                </div>
                <!-- item -->
                <div class="accordion-item">
                  <h3
                    class="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq06"
                  >
                    我可以使用哪個錢包？
                  </h3>
                  <div
                    id="faq06"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>Metamask</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- footer -->
      <footer class="footer">
        <div class="container text-center">
          <div>Atlantis -P © 2022</div>
        </div>
        <img
          src="img/footer.png"
          class="footer_img"
          alt=""
        >
      </footer>
      <!-- Connect Modal -->
      <div
        id="connectModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="connectModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="connectModalLabel"
                class="modal-title"
              >
                連結錢包
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body">
              <ul class="wallet-list">
                <li>
                  <img
                    src="img/metamask.png"
                    alt=""
                  >
                  Meta Mask
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Atlantis',
  data() {
    return {
      artList: [
        {
          id: '01',
          img: 'img/arts/01.jpg',
          name: '薛玉翎 YuRin-110',
          title: '青蛙小農',
        },
        {
          id: '02',
          img: 'img/arts/02.jpg',
          name: '蘇恩 熱狗日常',
          title: '波賽頓',
        },
        {
          id: '03',
          img: 'img/arts/03.jpg',
          name: '葉倢伃',
          title: '紫珊瑚魔女',
        },
        {
          id: '04',
          img: 'img/arts/04.jpg',
          name: '葉姿宜',
          title: '提格瑞',
        },
        {
          id: '05',
          img: 'img/arts/05.jpg',
          name: '林晏羽',
          title: '銀河',
        },
        {
          id: '06',
          img: 'img/arts/06.jpg',
          name: '蔡佳展 Q淡-40',
          title: '木屬性的亞特蘭提斯聖人',
        },
        {
          id: '07',
          img: 'img/arts/07.jpg',
          name: '楊知誠 惡魔腦多巴胺分泌',
          title: '大洋女妖雅特蘭娜',
        },
        {
          id: '08',
          img: 'img/arts/08.jpg',
          name: '蕭宇筑 小雨竹',
          title: '怪獸操控守衛機器人',
        },
        {
          id: '09',
          img: 'img/arts/09.jpg',
          name: '陳奕龍 野生阿宅',
          title: '九天玄女',
        },
        {
          id: '10',
          img: 'img/arts/10.jpg',
          name: '楊書府',
          title: '深山黑面虎爺將軍',
        },
        {
          id: '11',
          img: 'img/arts/11.jpg',
          name: '陳麗昕',
          title: 'stingray',
        },
        {
          id: '12',
          img: 'img/arts/12.jpg',
          name: '昴瞇',
          title: 'Guard',
        },
        {
          id: '13',
          img: 'img/arts/13.jpg',
          name: '謝宇農 Jerry',
          title: 'BASKEFOR LIFE',
        },
        {
          id: '14',
          img: 'img/arts/14.jpg',
          name: '洵之Shikaya',
          title: '松果探測機',
        },
        {
          id: '15',
          img: 'img/arts/15.jpg',
          name: '鄭婷之',
          title: '星之警衛',
        },
        {
          id: '16',
          img: 'img/arts/16.jpg',
          name: '凱西',
          title: '河豚戰士',
        },
        {
          id: '17',
          img: 'img/arts/17.jpg',
          name: '周秘羽',
          title: '咖衛',
        },
        {
          id: '18',
          img: 'img/arts/18.jpg',
          name: 'ENF',
          title: '魚乾號',
        },
        {
          id: '19',
          img: 'img/arts/19.jpg',
          name: '林彥婷',
          title: '人魚茉莉',
        },
        {
          id: '20',
          img: 'img/arts/20.jpg',
          name: '蘇恩 熱狗日常',
          title: '人魚',
        },
      ],
      btnMintDisabled: false,
    }
  },
  methods: {
    async Mint(aContract, signature, count) {
      try {
        return await aContract.whitelistMint(signature, 1, {
          value: `${count[1].toString()}`,
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async translate(tx) {
      try {
        const receipt = await tx.wait()
        this.$toast.success('blockHash: ' + receipt.blockHash)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
}
</script>

<style scoped></style>
